.filter-row {
  padding: 0 1rem 1rem;
  background: #e9ecef;
  display: flex;
  flex-wrap: wrap;
}
.filter-row:first-child {
  border-radius: 10px 10px 0 0;
}
.filter-row:last-child {
  border-radius: 0 0 10px 10px;
}
.filter-row + div {
  margin-top: 2px;
}
.filter-item {
  position: relative;
  margin-right: 1rem;
  margin-top: 1rem;
}
.filter-item .select2-container {
  min-width: 120px;
  max-width: 200px;
}
.filter-item-domain {
  flex: 1;
}
.filter-range .dropdown-menu {
  width: 200px;
}
.filter-range-labels {
  display: inline-flex;
}
.filter-range-labels > span {
  display: inline-block;
  max-width: 4ch;
}
.filter-range-fields {
  display: flex;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.filter-range-fields > div {
  flex: 1;
}
.filter-range-fields > div:first-child .form-control {
  border-radius: 5px 0 0 5px;
}
.filter-range-fields > div:last-child .form-control {
  border-radius: 0 5px 5px 0;
}
.filter-range-fields label {
  font-size: 0.7rem;
  color: #6c757d;
  margin: 0;
}
.filter-range-fields input::-webkit-outer-spin-button,
.filter-range-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.filter-range-fields input[type='number'] {
  -moz-appearance: textfield;
}
.filter-tags-dropdown {
  min-width: 250px;
  padding: 3rem;
}
.filter-tags-buttons {
  display: grid;
  grid-gap: 0.5rem;
}
.filter-tags-buttons.grid-2 {
  grid-template-columns: 1fr 1fr;
}
.filter-tags-buttons.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.filter-tags-buttons .btn {
  white-space: nowrap;
  --bs-btn-color: #212529;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #adb5bd;
  --bs-btn-hover-color: #212529;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #adb5bd;
  --bs-btn-focus-shadow-rgb: 152, 159, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: #b5bcc4;
}
.filter-tags-buttons .btn.active {
  background: hsl(314, 59%, 48%);
  color: #fff;
}
.filter-tags-buttons .btn:focus {
  box-shadow: none;
}
.filter-tags .badge {
  border-radius: 3px;
  font-family: sans-serif;
  background-color: hsl(314, 59%, 48%);
}
.filter-item .btn-close {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #da6e38
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  font-size: 7px;
  padding: 5px;
  display: block;
  opacity: 0.8;
}
.filter-item .btn-close:hover {
  background-color: #bc5623;
}
