@import url('https://fonts.cdnfonts.com/css/digital-numbers');

.masthead {
  //background-image: url("../../../Assests/Videos/-7c4d-4e97-b351-f5bd3e107a6c.mp4") !important;
}

.site-heading {
  .main-heading {
    color: #91F463;
    font-weight: 700 !important;

    @media (max-width: 768px) {
      color: #FFF;
      font-family: Tahoma;
      font-size: 16px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 24px;
    }
  }

  .counter-heading {
    font-weight: 400 !important;
    font-family: 'Digital Numbers', serif !important;
    font-size: 3.063rem !important;
    font-style: normal;
    line-height: 3.375rem;

    @media (max-width: 768px) {
      font-size: 2rem !important;
    }
  }

  .counter-heading-shimmer {
    width: 26.938rem !important;
    height: 3.938rem !important;

    @media (max-width: 768px) {
      width: 17.563rem !important;
        height: 2.563rem !important;
    }
  }
}

.download-link {
  width: 150px;
  height: 50px;
}

h1,.u-custom-font{
	text-align: center !important;
}
