.dashboard-top-nav {
  margin-bottom: 2rem;
  text-align: end;

  @media (min-width: 768px) {
    position: absolute;
    top: 2rem;
    right: 2rem;
    margin-bottom: 0;
  }

  .btn {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
      background-color: hsl(314, 59%, 48%);
      border-color: hsl(314, 59%, 48%);
    }

    .bi {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.inplay-logo {
  font-family: Tahoma, Arial, sans-serif !important;
  font-size: 25px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  margin-left: 4.188rem;
  margin-bottom: 0.7rem;

  @media (max-width: 768px) {
    margin-left: 0.5rem;
  }
}

.inplay-container h3 {
  margin: 0 auto !important;
  color: #FFFFFF;
}
.btncon {
	text-align: right;
    position: absolute;
    top: 19px;
    max-width: 100% !important;
}
.inplay-container button {
  padding: .4rem 3rem;
  color: #ffffff !important;
  background-color: #232b79 !important;
  border-radius: 5px;
  margin: 2px 4.188px 5px 1px;
  box-shadow: none;
  border: 1px solid transparent;

  text-align: center;
  font-family: Tahoma, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

}

.inplay-containerbutton:hover {
  color: #1A732D;
  background-color: #57f110;
  border-color: #91F463;
}

.navbar-background {
  background: #0B2911 !important;
}

div {
  border: none !important;
}

.container {
  @media (min-width: 768px) and (max-width: 991.98px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}


/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
	#login-nav-button {
	  display: none; /* Hide the button on screens with a width of 600px or less (typical for mobile devices) */
	}
  }