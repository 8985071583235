/* styles/LoginPage.module.scss */
.container1 {
	width: 300px;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
  
  
	.inputGroup {
	  margin-bottom: 20px;
  
	  label {
		display: block;
		margin-bottom: 5px;
		color: #666;
		text-align: left;
	  }
  
	  input {
		width: 100%;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 3px;
		color: #666;
	  }
	}
  
	
  }
  