.container1 {
	max-width: 400px;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
	text-align: center;
  }
  
  .formGroup {
	margin-bottom: 20px;
  }
  
  label {
	display: block;
	margin-bottom: 5px;
	text-align: left;
  }
  
  input[type="text"],
  input[type="email"] {
	width: 100%;
	padding: 8px;
	border: 1px solid #ccc;
	color: black;
	border-radius: 3px;
  }
  
  button[type="submit"] {
	width: 100%;
	padding: 10px;
	border: none;
	border-radius: 3px;
	background-color: #007bff;
	color: #fff;
	cursor: pointer;
  }
  
  button[type="submit"]:hover {
	background-color: #0056b3;
  }