@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');

.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.278125rem + 0.3375vw) !important;
}

.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-5 {
  font-size: 1.09375rem !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

/* rtl:begin:remove */

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.divider-text {
  text-align: center;
  font-size: 0.7rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.divider-text span {
  padding: 0 1rem;
  line-height: 1;
}
.divider-text:before,
.divider-text:after {
  content: '';
  height: 1px;
  background: #dee2e6;
  flex: 1;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
