.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  padding: 1rem;
}
@media (min-width: 992px) {
  .steps {
    padding: 2rem 10%;
  }
}
.steps:after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 10%;
  left: 10%;
  border-top: 1px dashed #d456b7;
  height: 1px;
  z-index: -1;
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-size: 1.125rem;
  transition: 0.4s;
  border: 1px dashed #d456b7;
  background: #fff;
  color: #d456b7;
}
.step.active {
  border: 1px solid hsl(314, 59%, 48%);
  background: hsl(314, 59%, 48%);
  color: #fff;
}
