.back-link-text {
    text-decoration: none;
    color: black;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.back-nav {
    display: flex;
    text-align: left;
    gap: 17px;
    transform: translateY(-50% -50%);
}

.blog-heading
{
	text-align: center;
	width: 100%;
}

.blog-banner{
	background-color: #EFF2FB;
}

h1 {
    font-size: 42px;
    margin-bottom: 20px;
    line-height: 1.2em;
    font-weight: 400;
    text-transform: none;
	text-align: right !important;
	padding-right: 5% !important;
}

.header-info h1 {
    margin: 0;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
}