

.site-content{
	display: flex;
}

.content-area-item
{
	width: 100%;
}

.side-bar{
	width: 30%;
}
.blog-date-author {
    align-items: center;
    border-bottom: 1px solid #ccc !important;
    color: #555;
    display: flex;
    font-family: Tahoma, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    margin-bottom: 1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 0;
    text-transform: uppercase;
}
.blog-space{ height: 20px;}
.blog-separator {
    color: #555;
    font-size: 16px;
    font-weight: 700;
    margin-left: .5rem;
    margin-right: .5rem;
}

#block-2 {
    padding: 0;
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.blog-image img{
}

.inside-right-sidebar {
    margin-top: 20px;
    margin-bottom: 20px;
}
.site-content {
    display: flex;
}

.page-numbers {
    text-decoration: none;
    font-family: 'League Spartan', sans-serif;
    background-color: #F0F0F0;
    color: black;
    padding: 17px 27px;
    border-radius: 50%;
}
.blog-item-site-main{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;	
}
.nav-links {
    display: flex;
    gap: 10px;
    align-items: center;
}
.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.paging-navigation .nav-links .current {
    font-weight: 700;
    background-color: black;
    color: white;
    padding: 17px 27px;
    border-radius: 50%;
    font-family: 'League Spartan', sans-serif;
    font-size: 16px;
}

.paging-navigation .nav-links .current {
    font-weight: 700;
    background-color: black;
    color: white;
    padding: 17px 27px;
    border-radius: 50%;
    font-family: 'League Spartan', sans-serif;
    font-size: 16px;
}

.paging-navigation {
    padding-top: 20px;
    padding-bottom: 20px !important;
}



.entry-title {
    font-size: 36px;
    color: #000;
    font-weight: 500;
}

.entry-title {
    margin-bottom: 0;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-size: 48px;
    margin-top: 35px;
}

.entry-content:not(:first-child), .entry-summary:not(:first-child), .page-content:not(:first-child) {
    margin-top: 10px;
}

.site-main
{
	padding: 10px;
}

.entry-title
{
	text-align: center !important;
}
.entry-summary h2
{
	font-size: 2rem !important;
}