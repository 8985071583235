.footer-container {
  text-align: right !important;
  position: relative;
}

.social-link{
	filter: invert(99%) sepia(5%) saturate(1179%) hue-rotate(212deg) brightness(117%) contrast(100%);
	padding: 10px;
	float:left; 
}

.legal .legal-links {
    display: flex;
  justify-content: space-between;
	list-style-type: none;
	width:50%;
	padding-right: 1%;
	float: right;
	margin: 10px;
}

.legal .legal-links a
{
	color: #ffffff;
	float:right;
}

li {
    text-align: -webkit-match-parent;
}
div {
    display: block;
}

.legal
{
	font-size: 80%;
}

.footer-legal {
	padding-left: 1%;
}