.error-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 83.25vh;
  width: 100vw;
  background-color: #f5f5f5;

  .error-image {
    width: 400px;
    max-width: 500px;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}