.privacy-container {
  margin: 16.563rem;
  padding-bottom: 13.438rem;

  @media (max-width: 768px) {
    margin: 6rem 1rem 2rem;
    padding-bottom: 2rem;
  }

  h3 {
    color: #2C2C2C;
    leading-trim: both;
    text-edge: cap;
    font-family: Tahoma, Arial, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 85.714% */

    @media (max-width: 768px) {
      font-size: 25px;
    }
  }

  p {
    color: #2C2C2C;
    leading-trim: both;
    text-edge: cap;
    font-family: Tahoma, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
}