@media (min-width: 992px) {
  .dashboard {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 275px auto;
    gap: 1rem;
  }
}

.dashboard-top-nav {
  margin-bottom: 2rem;
  text-align: end;
}

@media (min-width: 768px) {
  .dashboard-top-nav {
    position: absolute;
    top: 2rem;
    right: 2rem;
    margin-bottom: 0;
  }
}

.dashboard-top-nav .btn {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dashboard-top-nav .btn:hover {
  background-color: hsl(314, 59%, 48%);
  border-color: hsl(314, 59%, 48%);
}

.dashboard-top-nav .btn .bi {
  width: 1.5rem;
  height: 1.5rem;
}

.dashboard-content {
  padding: 2rem;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 1rem;
  }
  @media (min-width: 992px) {
    padding: 3rem;
  }
}

.dashboard-sidebar {
  align-self: flex-start;
  height: 100vh;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .dashboard-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 500px;
    z-index: 10001;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: all 0.303s ease-in-out;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .dashboard-sidebar {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .dashboard-sidebar.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.dashboard-sidebar .nav li {
  padding: 0.8rem 0;
}

.dashboard-sidebar .nav li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.dashboard-sidebar .nav a {
  color: #fff;
  text-decoration: none;
}

.dashboard-sidebar .nav a:hover {
  color: hsl(314, 59%, 48%);
}

.dashboard-sidebar .nav .bi {
  width: 1.3em;
  height: 1.3em;
  margin-right: 0.5rem;
}

.dashboard-tabs .nav-link {
  padding: 0.75rem 1.5rem;
  font-weight: bold;
}

.dashboard-tabs .card,
.dashboard-tabs .card-header,
.dashboard-tabs .card-body {
  border-radius: 0;
}

.dashboard-tabs .card-header {
  padding: 0;
}

.dashboard-tabs .card-header a {
  padding: 1rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-size: 1.3125rem;
  font-weight: 700;
  color: #113835;
}

.dashboard-tabs .card-header a svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.303s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dashboard-tabs .card-header a svg {
    transition: none;
  }
}

.dashboard-tabs .card-header a.collapsed svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

@media (max-width: 767.98px) {
  .dashboard-tabs .card {
    margin-bottom: 1rem;
  }
  .dashboard-tabs .card-header {
    border-bottom: 0;
  }
  .dashboard-tabs .card-header + .card-body {
    border-top: 1px solid var(--bs-border-color-translucent);
  }
  .dashboard-tabs .nav-tabs {
    display: none;
  }
}

@media (min-width: 768px) {
  .dashboard-tabs .nav-tabs {
    display: flex;
  }
  .dashboard-tabs .card {
    border-top: none;
  }
  .dashboard-tabs .card .card-header {
    display: none;
  }
  .dashboard-tabs .card .card-body {
    padding: 1.5rem;
  }
  .dashboard-tabs .card .collapse {
    display: block;
  }
}
