

.site-content{
	display: flex;
}

.content-area
{
	width: 70%;
}

.side-bar{
	width: 30%;
}

#block-2 {
    padding: 0;
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.inside-right-sidebar {
    margin-top: 20px;
    margin-bottom: 20px;
}
.site-content {
    display: flex;
}

.page-numbers {
    text-decoration: none;
    font-family: 'League Spartan', sans-serif;
    background-color: #F0F0F0;
    color: black;
    padding: 17px 27px;
    border-radius: 50%;
}

.nav-links {
    display: flex;
    gap: 10px;
    align-items: center;
}
.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.paging-navigation .nav-links .current {
    font-weight: 700;
    background-color: black;
    color: white;
    padding: 17px 27px;
    border-radius: 50%;
    font-family: 'League Spartan', sans-serif;
    font-size: 16px;
}

.paging-navigation .nav-links .current {
    font-weight: 700;
    background-color: black;
    color: white;
    padding: 17px 27px;
    border-radius: 50%;
    font-family: 'League Spartan', sans-serif;
    font-size: 16px;
}

.paging-navigation {
    padding-top: 20px;
    padding-bottom: 20px !important;
}

.read-more-button {
    background-color: #232b79;
	color: #ffffff !important;
    max-width: 176px;
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px 27px 20px 27px;
    font-size: 16px;
	text-align: center;
}

.read-more-button > a {
	color: #ffffff !important;
}

.entry-title {
    font-size: 36px;
    color: #000;
    font-weight: 500;
}

.entry-title {
    margin-bottom: 0;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-size: 48px;
    margin-top: 35px;
}

.entry-content:not(:first-child), .entry-summary:not(:first-child), .page-content:not(:first-child) {
    margin-top: 10px;
}

.site-main
{
	padding: 10px;
}